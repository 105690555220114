import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { setSystemParametersDataAction } from 'modules/redux/actions/systemParameters';
import { resourceGetAction } from 'modules/redux/ResourceFetcher';
import mrf from 'mrf.config';
import { setAuthDataAction } from './redux/actions/auth';
import { daoGet } from './Dao';
import useCurrentUser from './utils/useCurrentUser';
import { setPrintValueAction } from './redux/actions/print';
import usePrintMode from './utils/usePrintMode';
import { ToastContainer } from 'react-toastify';
import { startLogoutTimeout, RenewalWatcher } from './utils/LoginUtils';
import useDefaultLang from 'modules/utils/useDefaultLang';
import ErrorBoundary from 'modules/view/ErrorBoundary';
import 'react-toastify/dist/ReactToastify.css';

import 'tippy.js/dist/tippy.css';
import { notifyError } from './utils/notification';
import DevBanner from "./view/DevBanner";

export const appDispatch = {};

const Container = (props) => <div className='h-screen' {...props} />

export default function MRF({ children }) {
    appDispatch.value = useDispatch();

    const { t, i18n } = useTranslation();
    const user = useCurrentUser();
    const printMode = usePrintMode();
    const language = useDefaultLang();

    // Logged User
    useEffect(() => {
        (async () => {
            startLogoutTimeout();

            let userData = { fetched: true };
            try {
                if (localStorage.token) {
                    userData = {
                        ...userData,
                        ...await daoGet('/api/Users/LoggedUser'),
                    };
                }
            } catch (e) {
                if (e?.status === 404) {
                    delete localStorage.token;
                }
            } finally {
                appDispatch.value(setAuthDataAction(userData));
            }
        })()
    }, [user?.updateKey]); // eslint-disable-line

    // Translate page title
    useEffect(() => {
        if (mrf.pageTitle) {
            document.title = t(mrf.pageTitle);
        }
    }, [i18n.language]); // eslint-disable-line

    // Set default language
    useEffect(() => {
        localStorage.language = language;
    }, []); // eslint-disable-line

    // Set user language
    useEffect(() => {
        i18n.changeLanguage(user?.language);
        localStorage.language = user?.language ?? language;
    }, [user?.language]); // eslint-disable-line

    // Fetch systemParameters. This useEffect watches over user?.updateKey in case the user is not yet logged in.
    useEffect(() => {
        if (mrf.useSystemParameters && (user?.id || mrf.systemParametersMode !== 'auth')) {
            const fetchSystemParameters = async () => {
                try {
                    await resourceGetAction({ dispatch: appDispatch.value, action: setSystemParametersDataAction, endpoint: '/api/systemParameters/client' });
                } catch (e) {
                    notifyError('Critical: Unable to get system parameters. Please try to refresh the page (F5).');
                }
            }
            fetchSystemParameters();
        }

    }, [user?.updateKey]); // eslint-disable-line

    // Print
    useEffect(() => {
        if (printMode) {
            window.print();
            appDispatch.value(setPrintValueAction(false));
        }
    }, [printMode]); // eslint-disable-line

    const systemParameters = useSelector(state => state.systemParameters);

    if (mrf.waitForUser && user.fetched !== true) {
        return false;
    }

    if (mrf.useSystemParameters && !systemParameters) {
        return false;
    }

    if (mrf.useToast) {
        return <>
            <ErrorBoundary>
                <Container>
                    <ToastContainer
                        position='top-right'
                        autoClose={5000}
                        rtl={false}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <RenewalWatcher />
                    <DevBanner>
                        {children}
                    </DevBanner>
                </Container>
            </ErrorBoundary>
        </>
    }

    return <Container>
        <RenewalWatcher />
        {children}
    </Container>;
}
