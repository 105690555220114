import { daoGet } from 'modules/Dao';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import T from "./T";


/**
 * Uses dao and file-saver to download a file at a given endpoint.
 *
 * @param endpoint Url used to download the file
*/
HandleDownload.propTypes = {
    endpoint: PropTypes.string.isRequired // Query endpoint.
};
export default async function HandleDownload(endpoint) {
    try {
        const file = await daoGet(endpoint);
        saveAs(file.blob, file.name);
    } catch (e) {
        alert(T('An unexpected error has occurred!'));
        console.error(e);
    }
}