const urls = [
    [/\/api\/test\/example\/.*/, 1 << 20, 10 << 20] // This is an example of how to write an url size override.
]

const mrfConfig = {
    "applicationName": "fkp",
    "pageTitle": "FKP",
    "loginImage": "Content/login.png",
    "loginImageWidth": "300",
    "utc": true,
    "useMomentLocale": true,
    "useSystemParameters": true,
    "systemParametersMode": "public",
    "auth": {
        "waitForUser": true,
        "requirements": "Password must be at least 8 characters long.\nPasswords must have at least one lowercase letter.\nPasswords must have at least one uppercase letter.\nPasswords must have at least one digit.\nPasswords must have at least one special character.",
    },
    "validateForm": false,
    "useToast": true,
    "getAlertResponseLength": input => {
        // eslint-disable-next-line no-unused-vars
        for (let [regex, alert, _max] of urls) {
            if (regex.test(input)) { return alert; }
        }
    },
    "getMaxResponseLength": input => {
        // eslint-disable-next-line no-unused-vars
        for (let [regex, _alert, max] of urls) {
            if (regex.test(input)) { return max; }
        }
    },
    "showDevBanner": true,
}

export default mrfConfig;
