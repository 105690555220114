import * as actions from '../actions/form';

const form = (state, action) => {
    if (action.keys?.length > 0) {
        const keys  = [ ...action.keys ];
        const nestedAction = { ...action, keys: keys.splice(1) };
        const [ key ] = keys;

        if (Array.isArray(state)) {
            return state.map((obj, idx) => (
                idx === key
                    ? form(obj, nestedAction)
                    : obj
            ));
        } else {
            return {
                ...state,
                [key]: form(state?.[key], nestedAction),
            };
        }
    }
    if (action.type === actions.SET_FORM_VALUES){
        return {
            ...state,
            ...action.data,
        };
    } else {
        return null;
    }
}

export const forms = (state = {}, action) => {
    switch (action.type) {
        case actions.SET_FORM_VALUES: {
            const newState = { ...state };
            delete newState[action.form + 'Errors'];
            newState[action.form] = form(newState[action.form], action);

            if (action.meta) {
                newState[action.form]._meta = {
                    ...newState[action.form]._meta,
                    ...action.meta,
                };
            }

            return newState;
        }

        case actions.SET_FORM_META_VALUES: {
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    _meta: {
                        ...state[action.form]._meta,
                        ...action.meta,
                    },
                },
            };
        }

        case actions.DELETE_FORM: {
            const newState = { ...state };
            delete newState[action.form];
            return newState;
        }

        default:
            return state;
    }
}
