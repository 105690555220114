import PropTypes from 'prop-types';
import tw, { styled, theme } from "twin.macro";
import {
    justifyVariants,
    alignVariants,
    spacing
} from 'modules/layout/variants';

const Flex = styled.div.attrs(({
    spaceX,
    spaceY,
}) => ({
    className: `${spaceX ? ` space-x-${spaceX}` : ''}`
        + `${spaceY ? ` space-y-${spaceY}` : ''}`
}))`
    ${tw`flex`}
    ${({ flexGrow }) => flexGrow === 1 && tw`flex-grow`}
    ${({ direction }) => direction === 'col' ? tw`flex-col` : tw`flex-row`}
    ${({ justify }) => justify && justifyVariants[justify]}
    ${({ align }) => align && alignVariants[align]}
    width: ${({ width = 'auto' }) => theme('width')[width]};
    height: ${({ height }) => height && theme('height')[height]};
    gap: ${({ gap = 2 }) => gap && spacing[gap]};
    column-gap: ${({ gapX }) => gapX && theme('gap')[gapX]};
    row-gap: ${({ gapY }) => gapY && theme('gap')[gapY]};
`;

function deprecated(validator) {
    return (props, propName, ...rest) => {
        if (props[propName] != null) {
            console.warn(`${propName} is deprecated`);
        }
        return validator(props, propName, ...rest);
    }
}

Flex.propTypes = {
    spaceX: deprecated(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    spaceY: deprecated(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    gap: PropTypes.number,
    gapX: PropTypes.number,
    gapY: PropTypes.number,
    flexGrow: PropTypes.bool,
    direction: PropTypes.string,
    justify: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    align: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Flex;