import React, { useEffect } from 'react';
import { GlobalStyles } from 'twin.macro';
import Spinner from 'modules/view/Spinner';
import { TYPE_GET_LIST, TYPE_GET_RAW, useFetcherSelector } from 'modules/redux/ReduxHelper';
import MRF from 'modules/MRF';
import { useTranslation } from 'react-i18next';
import 'styles/overrideStyles';
import useDefaultLang from 'modules/utils/useDefaultLang';
import useCurrentUser from 'modules/utils/useCurrentUser';
import { globalTranslationTerms } from 'i18n';
import { useSelector } from 'react-redux';

const AppPublicLayout = React.lazy(() => import("AppPublicLayout"));
const AppInternalLayout = React.lazy(() => import("AppInternalLayout"));

export const apiPrefix = '';
// export const apiPrefix = 'http://localhost:59645'; // VS Addr

const isLocalHost = hostname => !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export default function App() {
    const { i18n } = useTranslation();
    const user = useCurrentUser();
    const language = useDefaultLang();

    const translationTerms = useFetcherSelector({
        selector: state => state.resources?.translationTerms,
        type: TYPE_GET_LIST,
        resource: 'translationTerms',
        endpoint: 'api/translationTerms',
    });

    const translation = useFetcherSelector({
        selector: state => state.resources?.translation,
        type: TYPE_GET_RAW,
        resource: 'translation',
        endpoint: 'api/translationLanguages/default',
        condition: () => true,
        dependencyList: [user, language],
    });

    const publicAreaActive = useSelector(state => state?.resources?.publicArea?.active)

    useEffect(() => {
        if (language) {
            const namespaces = [...new Set(translation?.terms?.map(t => t.term.session))];
            for (const ns of namespaces) {
                i18n.addResourceBundle(
                    language,
                    ns,
                    translation?.terms?.filter(t => t.term.session === ns).reduce(
                        (o, value) => ({ ...o, [value?.term?.code]: value?.translatedKey }),
                        {}
                    )
                );
            }
            i18n.changeLanguage(language);
        }
    }, [translation]);

    useEffect(() => {
        if (window.location.protocol === 'http:' && !isLocalHost(window.location.hostname)) {
            window.location.href = window.location.href.replace(
                /^http(?!s)/,
                'https'
            );
        }
    }, []);

    useEffect(() => {
        for (let term of translationTerms?.list?.map?.(id => translationTerms.byId[id]) ?? []) {
            if (!(term.session in globalTranslationTerms)) {
                globalTranslationTerms[term.session] = {}
            }

            globalTranslationTerms[term.session][term.code] = true;
        }
    }, [translationTerms]);

    if (!translationTerms) { return false; }

    const privateArea = !!user?.id && !publicAreaActive

    return <MRF>
        <Spinner />
        <GlobalStyles />
        {privateArea
            ? <AppInternalLayout />
            : <AppPublicLayout />
        }
    </MRF>
}